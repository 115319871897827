import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import { FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';
import { FaFlagUsa, FaFlag } from 'react-icons/fa'; // Import flag icons
import { useTranslation } from 'react-i18next';
import './i18n'; // Import i18n initialization


function App() {


  const { t, i18n } = useTranslation();
  const [activeSection, setActiveSection] = useState('about');
  const [isModalOpen, setModalOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      sections.forEach((section) => {
        if (
          section.offsetTop <= scrollPosition &&
          section.offsetTop + section.offsetHeight > scrollPosition
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* Header for fixed positioning */}
      {/* Floating Language Switcher */}
      <div className="floating-language-switcher">
        <button onClick={() => changeLanguage('en')} className={`language-button ${i18n.language === 'en' ? 'active' : ''}`} title="English">
          <FaFlagUsa className="language-icon" />
        </button>
        <button onClick={() => changeLanguage('de')} className={`language-button ${i18n.language === 'de' ? 'active' : ''}`} title="Deutsch">
          <FaFlag className="language-icon" />
        </button>
      </div>
      <div className="layout">
        <aside className="left-panel">
          <div className="sidebar">
                {/* Video Profile Element */}
          <video
            src={`${process.env.PUBLIC_URL}/profile.mov`}  // Ensure your .mov file is in the public directory
            className="profile-video-large"
            autoPlay
            loop
            muted
            playsInline // For mobile compatibility
            style={{ cursor: 'pointer' }}
            onClick={() => setModalOpen(true)} // Optional: Open modal or handle clicks
          ></video>
            {/* <img
              src={`${process.env.PUBLIC_URL}/profile.jpeg`}
              alt="Profile placeholder"
              className="profile-photo-large"
              onClick={() => setModalOpen(true)}
              style={{ cursor: 'pointer' }}
            /> */}
            <div className="sidebar-section">
              <h3>{t('name')}</h3>
              <p>{t('degree')}</p>
              {/* <p>{t('about')}</p> */}
            </div>
            <nav>
              <a href="#workplaces" className={activeSection === 'workplaces' ? 'active' : ''}>{t('experience')}</a>
              <a href="#education" className={activeSection === 'education' ? 'active' : ''}>{t('education')}</a>
              <a href="#languages" className={activeSection === 'languages' ? 'active' : ''}>{t('languages')}</a>
            </nav>
            <div className="sidebar-icons">
              <a href="mailto:anastasia.ivanova@tum.de" target="_blank" rel="noopener noreferrer">
                <FaEnvelope />
              </a>
              <a href="https://www.linkedin.com/in/anastasia-iv/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
              <a href="https://github.com/runasharp" target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </a>
            </div>
            <footer className="footer">
              <hr className="divider" />
              <p>© 2024 {t('name')}</p>
            </footer>
          </div>
        </aside>
        <main className="main">
        <section id="workplaces" className="card">
        <h3>{t('experience')}</h3>

        {/* CarOnSale Experience */}
        <div className="workplace-card" 
          onClick={() => window.open("https://www.caronsale.com/", "_blank")}
          role="button"
          tabIndex={0} // Makes the div focusable for accessibility
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open("https://www.caronsale.com/", "_blank");
            }
          }}>
        <div className="workplace-header">
          <img 
            src={`${process.env.PUBLIC_URL}/logos/cos.jpeg`} 
            alt="CarOnSale Logo" 
            className="workplace-logo" 
          />
          <h4>{t('carOnSale.title')}</h4>
          <span className="position">{t('carOnSale.position')}</span>
        </div>
          <div className="workplace-details">
            <span className="time-period">{t('carOnSale.time')}</span>
            <span className="working-hours">{t('carOnSale.workingHours')}</span>
          </div>
          
          <ul>
            <li>{t('carOnSale.description.part1')}</li>
            <li>{t('carOnSale.description.part2')}</li>
          </ul>
        </div>
        <hr className="divider" />

        {/* Mercedes-Benz Tech Innovation Experience */}

        <div className="workplace-card" 
          onClick={() => window.open("https://www.mercedes-benz-techinnovation.com/de", "_blank")}
          role="button"
          tabIndex={0} // Makes the div focusable for accessibility
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open("https://www.mercedes-benz-techinnovation.com/de", "_blank");
            }
          }}>
              <div className="workplace-header">
                <img 
                  src={`${process.env.PUBLIC_URL}/logos/mbti.jpeg`} 
                  alt="Mercedes-Benz Tech Innovation Logo" 
                  className="workplace-logo" 
                />
                <h4>{t('mercedesBenz.title')}</h4>
                <span className="position">{t('mercedesBenz.position')}</span>
              </div>
                <div className="workplace-details">
                  <span className="time-period">{t('mercedesBenz.time')}</span>
                  <span className="working-hours">{t('mercedesBenz.workingHours')}</span>
                </div>
                <ul>
                  <li>{t('mercedesBenz.description.part1')}</li>
                  <li>{t('mercedesBenz.description.part2')}</li>
                  <li>{t('mercedesBenz.description.part3')}</li>
                  <li>{t('mercedesBenz.description.part4')}</li>
                  <li>{t('mercedesBenz.description.part5')}</li>
                </ul>        
              </div>
              <hr className="divider" />

          {/* Kärcher Project Studies Experience */}
          <div className="workplace-card" 
          onClick={() => window.open("https://www.kaercher.com/", "_blank")}
          role="button"
          tabIndex={0} // Makes the div focusable for accessibility
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open("https://www.kaercher.com/", "_blank");
            }
          }}>
          <div className="workplace-header">
            <img 
              src={`${process.env.PUBLIC_URL}/logos/kaercher.jpeg`} 
              alt="Kärcher Logo" 
              className="workplace-logo" 
            />
            <h4>{t('kaercher.title')}</h4>
            <span className="position">{t('kaercher.position')}</span>
          </div>
          <div className="workplace-details">
            <span className="time-period">{t('kaercher.time')}</span>
            <span className="working-hours">{t('kaercher.workingHours')}</span>
          </div>
          <ul>
            <li>{t('kaercher.description.part1')}</li>
            <li>{t('kaercher.description.part2')}</li>
            <li>{t('kaercher.description.part3')}</li>
          </ul>
        </div>
      </section>
      <section id="education" className="card">
        <h3>{t('education')}</h3>

        {/* TUM - Master */}
        <div
          className="workplace-card"
          onClick={() => window.open('https://www.tum.de/', '_blank')}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open('https://www.tum.de/', '_blank');
            }
          }}
        >
          <div className="workplace-header">
            <img
              src={`${process.env.PUBLIC_URL}/logos/tum.jpeg`}
              alt="TUM Logo"
              className="workplace-logo"
            />
            <h4>{t('tum.title')}</h4>
            <span className="position">{t('tum.degree')}</span>
          </div>
          <div className="workplace-details">
            <span className="time-period">{t('tum.time')}</span>
          </div>
          <ul>
            <li>{t('tum.description.part1')}</li>
            <li>{t('tum.description.part2')}</li>
            <li>{t('tum.description.part3')}</li>
          </ul>
        </div>
        <hr className="divider" />

        {/* UTM - Bachelor */}
        <div
          className="workplace-card"
          onClick={() => window.open('https://www.utm.my/', '_blank')}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open('https://www.utm.md/en/', '_blank');
            }
          }}
        >
          <div className="workplace-header">
            <img
              src={`${process.env.PUBLIC_URL}/logos/utm.jpeg`}
              alt="UTM Logo"
              className="workplace-logo"
            />
            <h4>{t('utm.title')}</h4>
            <span className="position">{t('utm.degree')}</span>
          </div>
          <div className="workplace-details">
            <span className="time-period">{t('utm.time')}</span>
          </div>
          <ul>
            <li>{t('utm.description.part1')}</li>
            <li>{t('utm.description.part2')}</li>
            <li>{t('utm.description.part3')}</li>
            <li>{t('utm.description.part4')}</li>
          </ul>
        </div>
        <hr className="divider" />

        {/* Ilmenau - Exchange Semester */}
        <div
          className="workplace-card"
          onClick={() => window.open('https://www.tu-ilmenau.de/', '_blank')}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open('https://www.tu-ilmenau.de/', '_blank');
            }
          }}
        >
          <div className="workplace-header">
            <img
              src={`${process.env.PUBLIC_URL}/logos/ilmenau.jpeg`}
              alt="Ilmenau Logo"
              className="workplace-logo"
            />
            <h4>{t('ilmenau.title')}</h4>
            <span className="position">{t('ilmenau.degree')}</span>
          </div>
          <div className="workplace-details">
            <span className="time-period">{t('ilmenau.time')}</span>
          </div>
          <ul>
            <li>{t('ilmenau.description.part1')}</li>
          </ul>
        </div>
        <hr className="divider" />

        {/* Gloucestershire - Exchange Semester */}
        <div
          className="workplace-card"
          onClick={() =>
            window.open(
              'https://www.glos.ac.uk/',
              '_blank'
            )
          }
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open(
                'https://www.glos.ac.uk/',
                '_blank'
              );
            }
          }}
        >
          <div className="workplace-header">
            <img
              src={`${process.env.PUBLIC_URL}/logos/glos.jpeg`}
              alt="Gloucestershire Logo"
              className="workplace-logo"
            />
            <h4>{t('gloucestershire.title')}</h4>
            <span className="position">{t('gloucestershire.degree')}</span>
          </div>
          <div className="workplace-details">
            <span className="time-period">{t('gloucestershire.time')}</span>
          </div>
        </div>
      </section>
    <section id="languages" className="card">
      <h3>{t('languages')}</h3>

      {/* English - C1/C2 */}
      <div className="language-bar-container">
        <p>{t('language.english')}</p>
        <div className="language-bar">
          <div className="fill" style={{ width: '90%' }}></div> {/* 90% for C1/C2 */}
        </div>
      </div>

      {/* German - C1 */}
      <div className="language-bar-container">
        <p>{t('language.german')}</p>
        <div className="language-bar">
          <div className="fill" style={{ width: '85%' }}></div> {/* 85% for C1 */}
        </div>
      </div>      

      {/* Russian - C2 */}
      <div className="language-bar-container">
        <p>{t('language.russian')}</p>
        <div className="language-bar">
          <div className="fill" style={{ width: '100%' }}></div> {/* 100% for C2 */}
        </div>
      </div>

      {/* Romanian - B2/C1 */}
      <div className="language-bar-container">
        <p>{t('language.romanian')}</p>
        <div className="language-bar">
          <div className="fill" style={{ width: '75%' }}></div> {/* 75% for B2/C1 */}
        </div>
      </div>

        {/* Japanese - A2 */}
        <div className="language-bar-container">
        <p>{t('language.japanese')}</p>
        <div className="language-bar">
          <div className="fill" style={{ width: '20%' }}></div> {/* 20% for A2 */}
        </div>
      </div>

    
    </section>
        </main>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={() => setModalOpen(false)}>&times;</span>
            <img
              src={`${process.env.PUBLIC_URL}/profile.jpeg`}
              alt="Profile placeholder"
              className="modal-image"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
